body {
    margin: 0;
    background-color: rgb(116, 116, 194);
}
.app{
   
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.search{
    display: flex;
    display: inline;
    border: none;
    outline: none;
    margin-top: 2rem;
    border-radius: 10px;
    padding: 10px;
    background-color: whitesmoke;

}
.search>input{
    border: none;
    background-color: transparent;
    outline: none;
}
.search>button{
    border: none;
    outline: none;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: orange;

    cursor: pointer;
}
.weatherdata{
    gap: 2.5rem;
    margin-top: 3rem;
    background-color: whitesmoke;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 300px;
    border-radius:30px ;
}
.icon{
    border: black;
    color: royalblue;
    
}
.black{
    background-color: black;
    height: 80PX;
    width: 65%;
    color: white;
    align-items: center;
display: flex;
box-sizing: border-box;
padding: 10px;
gap: 2rem;
}
.green{
    background-color: rgb(65, 148, 65);
    height: 80PX;
    width: 35%;
    justify-content: center;
    text-align: center;
}
.blackwhite{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.bottomdata{
display: flex;
gap: 1.5rem;
margin-top: -20px;
}
.bd{
    display: flex;
    align-items: center;
    gap: 10px;
    
}
.bdin{
    display: flex;
    flex-direction: column;
}
.dsin>span{
    font-size: 10px;
}
.weatherdata>img{
    width: 80px;
}
.blackdiv{
    text-align: center;
}
.blackdiv>P{
    margin-top: -10px;
    text-align: left;
    font-size: 12px;
    font-weight: 100;
}
.search>button:hover{
background-color: aquamarine;
}
.search>button:active{
background-color: rgb(200, 10, 38);
font-weight: 600;
}

